import React, { useEffect, useState, useRef } from 'react';
import Avatar from "@mui/material/Avatar";
import { useReactToPrint } from 'react-to-print';
import { alpha, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Divider from "@mui/material/Divider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import List from "@mui/material/List";
import Div from "@jumbo/shared/Div";
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';
import { memberService } from 'app/services/member-services';
import Print from 'app/shared/Components/Print';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { API_IMAGES_URL, MONTHS } from 'app/utils/constants/config';

const MemberDetail = ({ member, onClose, authUser }) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [years, setYears] = useState([]);
  const [monthsPaid, setMonthsPaid] = useState([]);
  const [qtyPaid, setQtyPaid] = useState({});

  const componentRef = useRef(null);

  useEffect(() => {
    fetchYears();
  }, [])

  const fetchYears = () => {
    memberService.getYearsByMember(member._id)
      .then((data) => {
        // Generate years and populate with existing data.
        if (!data) {
          addYear(2023);
        } else {
          data.years.forEach(element => {
            const qty = element.months.reduce((prevValue, currentValue) => prevValue + (currentValue.paid ? 1 : 0), 0);
            setQtyPaid({ ...qtyPaid, [element.year]: qty });
          });
          setYears(data.years);
        }
      });
  }

  const handlePay = () => {
    setOpen(true);
  }

  const pay = () => {
    const lastPayObject = monthsPaid.reduce((prev, current) =>
      prev.pos > current.pos ? prev : current,
    );
    addPaymentMutation.mutate({
      years,
      monthsPaid,
      lastPay: lastPayObject.pos,
      member: member._id
    });
  }

  const addPaymentMutation = useMutation(memberService.addMemberPayments, {
    onSuccess: () => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Se pagó satisfactoriamente.',
        showConfirmButton: false,
        timer: 2500
      });
      setOpen(false);
      onClose();
      handlePrint();
      navigate(`/app/members/${member.category}`);
    }
  });

  const handleChangeMonths = (event, y, m) => {
    const { checked } = event.target;
    let newYears = JSON.parse(JSON.stringify(years));
    let year = { ...newYears[y] } // select a specific year.
    year.months[m].paid = checked // update specific month.
    year.months[m].amount = generateAmount(authUser.prices) // update specific month.
    newYears[y] = year;
    setYears(newYears);

    if (checked) {
      // Generate list of months for payments.
      setMonthsPaid(prevMonthsPaid => [...prevMonthsPaid, {
        year: year.year,
        month: year.months[m].month,
        amount: generateAmount(authUser.prices),
        pos: year.year.toString() + year.months[m].month
      }]);
    } else {
      // Remove element the list of payments.
      setMonthsPaid(oldValue => oldValue.filter((_, i) => _.pos !== (year.year.toString() + year.months[m].month)));
    }
  };

  const generateAmount = (price) => {
    return (+moment(new Date()).format("DD")) > 15 ? price[member.type + 'Late'] : price[member.type]
  }

  // Agregar nuevo año
  const addYear = (year) => {
    const initialYear = Array(12).fill({}).map((_, i) => ({ month: i + 1, amount: 0, paid: false }))
    setYears([...years, {
      year: year,
      months: initialYear
    }])
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const disableCheck = (month, item, y) => {
    let disabled = false;
    if (month.month <= qtyPaid[item.year]) {
      disabled = true;
    } else {
      const item = years[y].months.reduce((acc, cur) => acc + (cur.paid ? 1 : 0), 0);
      if (month.month > (item + 1)) {
        disabled = true;
      }
    }

    return disabled
  }

  const getLastPay = (value) => {
    const year = value.slice(0, 4);
    const month = value.slice(4);

    return `${month} / ${year}`;
  }

  return (
    <Div sx={{ m: theme => theme.spacing(-2.5, -3) }}>
      <List disablePadding>
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            {member?.image && <Avatar
              src={`${API_IMAGES_URL}${member?.image.src}.${member?.image.format}`}
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, .15)
              }}
            >
              <CorporateFareIcon sx={{ color: 'primary.light' }} />

            </Avatar>}
          </ListItemAvatar>
          <div style={{ display: "none" }}>
            <div ref={componentRef}>
              <Print data={member} authUser={authUser} monthsPaid={monthsPaid} />
            </div>
          </div>
          <ListItemText
            primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Nombre y Apellido</Typography>}
            secondary={<Typography variant={"h5"} mb={0}>{member.firstName} {member.lastName}</Typography>}
          />
          {member.category && (
            <ListItemText
              primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Categoria</Typography>}
              secondary={<Typography variant={"h5"} mb={0}>{member.category}</Typography>}
            />
          )}
        </ListItem>
        <Divider component={"li"} />
        <ListItem sx={{ px: 4 }}>
          <ListItemAvatar sx={{ minWidth: 66 }}>
            <Avatar
              variant="rounded"
              sx={{
                height: 48,
                width: 48,
                bgcolor: theme => alpha(theme.palette.primary.main, .15)
              }}
            >
              <MailOutlineIcon sx={{ color: 'primary.light' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Ultimo pago</Typography>}
            secondary={
              <Typography variant={"h5"} mb={0}>
                {member.lastPay && getLastPay(member.lastPay)}
              </Typography>}
          />
          <ListItemText
            primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Monto Cuota</Typography>}
            secondary={
              <Typography variant={"h5"} mb={0}>
                {authUser.prices && `$ ${authUser.prices[member.type]}`}
              </Typography>}
          />
        </ListItem>
        <Divider component={"li"} />
        <ListItem>
          {years.map((item, y) => {
            return <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" key={y}>
              <FormLabel component="legend">{item.year}</FormLabel>
              <FormGroup>
                {item.months.map((month, m) => {
                  return <FormControlLabel key={m}
                    control={
                      <Checkbox
                        disabled={disableCheck(month, item, y)}
                        checked={month.paid}
                        onChange={(e) => handleChangeMonths(e, y, m)}
                        name={month.month.toString()}
                      />
                    }
                    label={MONTHS[month.month]}
                  />
                })}
              </FormGroup>
            </FormControl>
          })}
        </ListItem>
        <ListItem sx={{ px: 4 }}>
          <ListItemText
            secondary={<Button variant="contained" onClick={handlePay}>Pagar</Button>}
          />
          <ListItemText
            primary={years.length &&
              <Button
                variant="outline"
                onClick={() => addYear((years[years.length - 1].year) + 1)}>Agregar {(years[years.length - 1].year) + 1}
              </Button>}
          />
        </ListItem>
        <Divider component={"li"} />
      </List>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Desea pagar los meses marcados ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta desición no podra ser cancelada luego de aceptada.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>No</Button>
          <Button onClick={() => pay()} autoFocus variant={"contained"}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Div>
  );
};

export default MemberDetail;
