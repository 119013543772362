import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../utils/constants/paths";


const Logo = ({ mini, mode, sx }) => {
    return (
        <Div sx={{ display: "inline-flex", ...sx }} style={{ backgroundColor: "#1697CD", padding: 10 }}>
            <Link href={'/app/members/all'}>
                {
                    !mini ?
                        <img src={mode === "light" ? `${ASSET_IMAGES}/logo_administralo.png` : `${ASSET_IMAGES}/logo_administralo.png`} alt="Administralo" width="100%" />
                        :
                        <img src={mode === "light" ? `${ASSET_IMAGES}/logo-short.png` : `${ASSET_IMAGES}/logo_administralo.png`} alt="Administralo" />
                }
            </Link>
        </Div >
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
