import React from 'react';
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FoldersList from "../FoldersList";
import LabelsList from "../LabelsList";
import Div from "@jumbo/shared/Div";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ContactForm from "../MemberForm";
import useContactsApp from "../../hooks/useContactsApp";
import { ASSET_AVATARS } from "../../../../../utils/constants/paths";
import { getAssetPath } from "../../../../../utils/appHelpers";
import MemberForm from '../MemberForm';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const ContactsAppSidebar = () => {
    const { authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const { setContactsListRefresh } = useContactsApp();

    const handleContactAdd = React.useCallback(() => {
        hideDialog();
        setContactsListRefresh(true);
    }, [hideDialog, setContactsListRefresh]);

    const showAddContactDialog = React.useCallback(() => {
        showDialog({
            title: "Nuevo",
            content: <MemberForm onSave={handleContactAdd} authUser={authUser} />
        });
    }, [handleContactAdd, showDialog]);

    return (
        <React.Fragment>
            <Button
                fullWidth
                disableElevation
                variant={"contained"}
                startIcon={<PersonAddIcon />}
                sx={{
                    mb: 2,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    }
                }}
                onClick={showAddContactDialog}
            >Nuevo</Button>
            <FoldersList />
            {/* <LabelsList /> */}
        </React.Fragment>
    );
};
export default React.memo(ContactsAppSidebar);
