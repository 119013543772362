import React from 'react';
import ContactsIcon from "@mui/icons-material/Contacts";
import StarsIcon from "@mui/icons-material/Stars";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FolderItem from "./FolderItem";
import { useParams } from "react-router-dom";
import StyledMenu from "../../../../../shared/StyledMenu";

const folders = [
    {
        icon: <ContactsIcon fontSize={"small"} />,
        label: "Todos",
        slug: "all",
        path: "/app/members/all"
    },
    {
        icon: <AccessTimeIcon fontSize={"small"} />,
        label: "Temporal",
        slug: "temporal",
        path: "/app/members/temporal"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "Cuarta",
        slug: "cuarta",
        path: "/app/members/cuarta"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "Quinta",
        slug: "quinta",
        path: "/app/members/quinta"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "Sexta",
        slug: "sexta",
        path: "/app/members/sexta"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2010",
        slug: "2010",
        path: "/app/members/2010"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2011",
        slug: "2011",
        path: "/app/members/2011"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2012",
        slug: "2012",
        path: "/app/members/2012"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2013",
        slug: "2013",
        path: "/app/members/2013"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2014",
        slug: "players",
        path: "/app/members/2014"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2015",
        slug: "2015",
        path: "/app/members/2015"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2016",
        slug: "2016",
        path: "/app/members/2016"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2017",
        slug: "2017",
        path: "/app/members/2017"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2018",
        slug: "2018",
        path: "/app/members/2018"
    },
    {
        icon: <StarsIcon fontSize={"small"} />,
        label: "2019",
        slug: "2019",
        path: "/app/members/2019"
    },
    // {
    //     icon: <DeleteIcon fontSize={"small"} />,
    //     label: "Trash",
    //     slug: "trash",
    //     path: "/app/contacts/trash"
    // },
];

const FoldersList = () => {
    const params = useParams();
    return (
        <StyledMenu sx={{ mb: 2 }}>
            {
                folders.map((folder, k) => (
                    <FolderItem
                        key={k}
                        path={folder.path}
                        label={folder.label}
                        icon={folder.icon}
                        selected={folder.slug === params?.category}
                    />
                ))
            }
        </StyledMenu>
    );
};

export default React.memo(FoldersList);
