import React from 'react';
import Header from "./Header";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { alpha } from "@mui/material/styles";
import About from "./components/About";
import UserProfileSidebar from "./UserProfileSidebar";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { useLocation } from 'react-router-dom';

const initialProfile = {
    firstName: "Lionel Andres",
    lastName: "Messi",
    dni: 33016244,
    birthday: "24/06/1987",
    type: {
        player: true,
        socio: false,
        employye: false
    },
    gender: "M",
    phone: 2644035665,
    lastPay: 202303,
    categoryPlayer: "Sexta"
}

const UserProfile = () => {
    const { state } = useLocation();
    const profile = state || initialProfile;
    const { theme } = useJumboTheme();

    return (
        <JumboContentLayout
            header={<Header profile={profile} />}
            sidebar={<UserProfileSidebar profile={profile} />}
            layoutOptions={{
                header: {
                    sx: {
                        mt: -4,
                        mb: -7.25,
                        mx: { xs: -4, lg: -6 },
                        p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                        background: `#002447 url(${getAssetPath(`${ASSET_IMAGES}/profile-header.jpeg`, "1920x580")}) no-repeat center`,
                        backgroundSize: 'cover',
                        color: 'common.white',
                        position: 'relative',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha(theme.palette.common.black, .65)
                        }
                    }
                },
                sidebar: {
                    sx: {
                        mr: 3.75,
                        width: { xs: '100%', lg: '33%' },
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0,
                            mr: 0,
                            order: 2
                        }
                    }
                },
                wrapper: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column'
                        }
                    }
                },
                main: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0
                        }
                    }
                }
            }}
        >
            <About profile={profile} />
            {/* <Biography />
            <Events sx={{ mb: { xs: 3.75, lg: 0 } }} /> */}
        </JumboContentLayout>
    );
};

export default UserProfile;
