import React from 'react';
import Avatar from "@mui/material/Avatar";
import { ListItemIcon, ListItemText, ThemeProvider, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { API_IMAGES_URL } from 'app/utils/constants/config';

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const { setAuthToken, authUser } = useJumboAuth();

    const onLogout = () => {
        setAuthToken(null);
        navigate("/user/login");
    };

    return (
        <ThemeProvider theme={theme}>
            {authUser &&
                <JumboDdPopover
                    triggerButton={
                        <Avatar
                            src={`${API_IMAGES_URL}/${authUser?.logo}`}
                            sizes={"small"}
                            sx={{ boxShadow: 25, cursor: 'pointer' }}
                        />
                    }
                >
                    <Div sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        p: theme => theme.spacing(2.5),
                    }}>
                        <Avatar src={authUser.avatar} alt={authUser.firstName} sx={{ width: 60, height: 60, mb: 2 }} />
                        <Typography variant={"h5"}>{authUser.firstName} {authUser.lastName}</Typography>
                        <Typography variant={"body1"} color="text.secondary">{authUser.dni}</Typography>
                    </Div>
                    <Divider />
                    <nav>
                        <List disablePadding sx={{ pb: 1 }}>
                            <ListItemButton>
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    <PersonOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Perfil" sx={{ my: 0 }} />
                            </ListItemButton>
                            <ListItemButton onClick={onLogout}>
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Salir" sx={{ my: 0 }} />
                            </ListItemButton>
                        </List>
                    </nav>
                </JumboDdPopover>
            }
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
