import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};

authServices.getCurrentUser = async () => {
    const { data } = await jwtAuthAxios.get("/profile");
    return data;
};

authServices.signIn = async (loginCreds) => {
    const { data } = await jwtAuthAxios.post('/auth/email/login', loginCreds);
    return data;
};

export default authServices;