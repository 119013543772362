import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { API_IMAGES_URL } from 'app/utils/constants/config';

const Print = React.forwardRef((props, ref) => {
  const { data, authUser, monthsPaid } = props;

  const total = () => {
    return monthsPaid.reduce(
      (prevValue, currentValue) => prevValue + currentValue.amount,
      0
    );
  };

  return (
    <div>
      <div id="invoiceCapture">
        <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
          <div className="w-100">
            <img
              src={`${API_IMAGES_URL}${authUser?.logo}`}
              width={80}
              height={80}
            />
            <h4 className="fw-bold my-2">{authUser?.name}</h4>
            {authUser?.foundation && (
              <h6 className="fw-bold text-secondary mb-1">
                Fundado el: {authUser.foundation}
              </h6>
            )}
            {authUser?.address && (
              <h6 className="fw-bold text-secondary mb-1">
                {authUser.adddress}
              </h6>
            )}
            {authUser?.cuit && (
              <h6 className="fw-bold text-secondary mb-1">
                Cuit: {authUser.cuit}
              </h6>
            )}
          </div>
          {/* <div className="text-end ms-4">
            <h6 className="fw-bold mt-1 mb-2">Monto:</h6>
            <h5 className="fw-bold text-secondary"> $ {total()}</h5>
          </div> */}
        </div>
        <div className="p-4">
          <Row className="mb-4">
            <Col md={8}>
              <div className="fw-bold">
                Facturado a: Familiar/Tutor de {data.lastName} {data.firstName}
              </div>
            </Col>
            <Col md={4}>
              <div className="fw-bold mt-2">Fecha:</div>
              <div>{moment(new Date()).format('DD/MM/YYYY')}</div>
            </Col>
          </Row>
          <Table className="mb-0">
            <thead>
              <tr>
                <th>Apellido y Nombre</th>
                <th>Categoria</th>
                <th className="text-end">Mes/Año</th>
                <th className="text-end">Monto</th>
              </tr>
            </thead>
            <tbody>
              {monthsPaid.map((item, i) => {
                return (
                  <tr id={i} key={i}>
                    <td>
                      {data?.lastName} {data?.firstName}
                    </td>
                    <td>{data?.category}</td>
                    <td className="text-end" style={{ width: '100px' }}>
                      {item.month}/{item.year}
                    </td>
                    <td className="text-end" style={{ width: '100px' }}>
                      $ {item.amount}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Table>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr className="text-end">
                <td></td>
                <td className="fw-bold" style={{ width: '100px' }}>
                  TOTAL
                </td>
                <td className="text-end" style={{ width: '100px' }}>
                  $ {total()}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <hr className="mt-4 mb-3" />
    </div>
  );
});

export default Print;
