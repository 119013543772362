import React, { useReducer, useState } from 'react';
import * as yup from "yup";
import { useMutation } from "react-query";
import { contactService } from "../../../../../services/contact-services";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import JumboAvatarField from "@jumbo/components/JumboFormik/JumboAvatarField";
import Div from "@jumbo/shared/Div";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Avatar, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Step, StepContent, StepLabel, Stepper, TextField } from '@mui/material';
import { memberService } from 'app/services/member-services';
import jwtAuthAxios from 'app/services/auth/jwtAuth';
import { API_URL } from 'app/utils/constants/config';

const baseImgUrl = "https://res.cloudinary.com/sanjua/image/upload/v1688669219/";

const validationSchema = yup.object({
  firstName: yup
    .string('Ingresa un nombre')
    .required('Nombre es requerido'),
  lastName: yup
    .string('Ingresa el apellido')
    .required('El apellido es requerido'),
  dni: yup
    .string('Ingresa un dni')
    .required('Dni es requerido'),
});
const initialValues = {
  firstName: "",
  lastName: "",
  type: 'player',
  dni: "",
  birthday: new Date(),
  socioNro: '',
  phone: "",
  email: "",
  image: "",
  gender: "M",
  relation: false,
  archived: false,
  category: 'cuarta'
};

const typeMembers = [
  {
    label: "Jugador",
    value: "player"
  },
  {
    label: "Socio",
    value: "socio"
  }
]

const categories = {
  player: [
    { label: "Cuarta", value: "cuarta" },
    { label: "Quinta", value: "quinta" },
    { label: "Sexta", value: "sexta" },
    { label: "2008", value: "2008" },
    { label: "2009", value: "2009" },
    { label: "2010", value: "2010" },
    { label: "2011", value: "2011" },
    { label: "2012", value: "2012" },
    { label: "2013", value: "2013" },
    { label: "2014", value: "2014" },
    { label: "2015", value: "2015" },
    { label: "2016", value: "2016" },
    { label: "2017", value: "2017" },
    { label: "2018", value: "2018" },
    { label: "2019", value: "2019" }
  ],
  socio: [
    { label: "Activo", value: "activo" },
    { label: "Juvenil", value: "juvenil" },
    { label: "Vitalicio", value: "vitalicio" }
  ]
}

const MemberForm = ({ member, onSave, authUser, edit = false }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [image, setImage] = useState(null);


  const Swal = useSwalWrapper();

  const addMemberMutation = useMutation(memberService.addMember, {
    onSuccess: () => {
      onSave();
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Se agrego satisfactoriamente.',
        showConfirmButton: false,
        timer: 2500
      });
    }
  });

  const updateMemberMutation = useMutation(memberService.updateMember, {
    onSuccess: () => {
      onSave();
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Se actualizo satisfactoriamente.',
        showConfirmButton: false,
        timer: 2500
      });
    }
  });

  const saveMemberMutation = React.useMemo(() => {
    return edit ? updateMemberMutation : addMemberMutation;
  }, [member, updateMemberMutation, addMemberMutation]);

  const onMemberSave = (data, { setSubmitting }) => {
    setSubmitting(true);
    saveMemberMutation.mutate({ ...data, image: image });
    setSubmitting(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const uploadImage = async (file) => {
    let bodyContent = new FormData();
    bodyContent.append("institution", authUser.slug);
    bodyContent.append("file", file);
    const { data } = await jwtAuthAxios.post(`${API_URL}/members/image`, bodyContent);
    setImage({
      src: data.public_id,
      id: data.asset_id,
      format: data.format
    })
  }

  return (
    <Formik
      validateOnChange={true}
      initialValues={member ? member : initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onMemberSave}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <Div
            sx={{
              '& .MuiTextField-root': {
                mb: 3
              },
            }}
          >
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>Tipo de miembro</StepLabel>
                <StepContent>
                  <FormGroup>
                    <div>
                      <FormControl sx={{ m: 1, minWidth: 100 }}>
                        <Select
                          labelId={`demo-simple-select-autowidth-label-type`}
                          id={`demo-simple-select-autowidth-type`}
                          value={values.type}
                          onChange={(event) => {
                            if (event.target.value === 'socio') {
                              setFieldValue('category', 'activo')
                            } else {
                              setFieldValue('category', 'cuarta')
                            }
                            setFieldValue('type', event.target.value)
                          }}
                          label="Tipo"
                        >
                          {typeMembers.map(item => {
                            return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    {typeMembers.map(item => {
                      return <div key={item.value}>
                        <div>
                          {values.type === item.value &&
                            <>
                              <FormControl sx={{ m: 1, minWidth: 100 }}>
                                <InputLabel id={`demo-simple-select-autowidth-label-${item.value}`}>Categoria</InputLabel>
                                <Select
                                  labelId={`demo-simple-select-autowidth-label-${item.value}`}
                                  id={`demo-simple-select-autowidth-${item.value}`}
                                  value={values.category}
                                  onChange={(event) => setFieldValue(`category`, event.target.value)}
                                  autoWidth
                                  label="Categoria"
                                >
                                  {categories[`${item.value}`].map(item => {
                                    return <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                  })}
                                </Select>
                              </FormControl>
                            </>
                          }
                        </div>
                      </div>
                    })}
                  </FormGroup>
                  <Div sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Continuar
                      </Button>
                    </div>
                  </Div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Datos Personales
                </StepLabel>
                <StepContent>
                  <Avatar sx={{ width: 48, height: 48 }} alt={member?.firstName} src={`${baseImgUrl}${member?.image?.src}.${member?.image?.format}`} />
                  <JumboAvatarField
                    name="image"
                    alt={"user profile pic"}
                    onFileSelection={(file) => {
                      uploadImage(file.file)
                      setFieldValue("image", file.src)
                    }}
                    sx={{ width: 80, height: 80, margin: '0 auto 24px' }}
                  />
                  <JumboTextField fullWidth size="small" variant="outlined" name="dni" label="Dni" />
                  <JumboTextField fullWidth size="small" variant="outlined" name="firstName" label="Nombres" />
                  <JumboTextField fullWidth size="small" variant="outlined" name="lastName" label="Apellidos" />
                  <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={initialValues.gender}
                      label="Sexo"
                      onChange={(event) => setFieldValue('gender', event.target.value)}
                    >
                      <MenuItem value="M">Masculino</MenuItem>
                      <MenuItem value="F">Femenino</MenuItem>
                      <MenuItem value="O">Otro</MenuItem>
                    </Select>
                  </FormControl>
                  {values.type === 'socio' && <JumboTextField fullWidth size="small" variant="outlined" name="socioNro" label="Nro Socio/Miembro" />}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      name="birthday"
                      label="Fecha de nacimiento"
                      inputFormat="dd/MM/yyyy"
                      value={values.birthday}
                      defaultValue={new Date()}
                      disableFuture
                      openTo="year"
                      onChange={(event, newValue) => {
                        setFieldValue('birthday', event)
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <JumboTextField fullWidth size="small" variant="outlined" name="phone" label="Teléfono" />
                  <JumboTextField fullWidth size="small" variant="outlined" name="email" label="Email" />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.relation}
                          onChange={(e) => {
                            setFieldValue('relation', e.target.checked)
                          }}
                          name="relation" />
                      }
                      label="Hermano/Familiar/Tutor"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.archived}
                          onChange={(e) => {
                            setFieldValue('archived', e.target.checked)
                          }}
                          name="archived" />
                      }
                      label="Miembro temporal / a prubea"
                    />
                  </FormGroup>
                  <Div sx={{ mb: 2 }}>
                    <div>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting || saveMemberMutation.isLoading}
                      >{edit ? 'Actualizar' : 'Guardar'}</LoadingButton>
                      <Button
                        // disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Volver
                      </Button>
                    </div>
                  </Div>
                </StepContent>
              </Step>
            </Stepper>
          </Div>
        </Form>
      )}
    </Formik>
  );
};
MemberForm.defaultProps = {
  onSave: () => {
  }
};
export default MemberForm;
