import React, { useState, useCallback } from 'react';
import { debounce } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import CustomDialog from 'app/pages/components/mui/Dialogs/CustomDialog';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import MemberForm from 'app/pages/apps/members/components/MemberForm';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'app/utils/constants/config';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import MemberDetail from 'app/pages/apps/members/components/MemberDetail/MemberDetail';

const SearchGlobal = ({ sx }) => {
    const navigate = useNavigate();
    const { authUser } = useJumboAuth();
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedValue, setSelectedValue] = useState(items);
    const { showDialog, hideDialog } = useJumboDialog();

    // Debounce for request search.
    const search = debounce(async (event) => {
        const { value } = event.target;
        if (value.length > 6) {
            const response = await fetch(`${API_URL}/members?dni=${value}`).then();
            const members = await response.json();
            if (members.length) {
                setItems(members)
                showDialog({
                    content: <MemberDetail member={members[0]} onClose={hideDialog} authUser={authUser} />
                });
            } else {
                setItems([]);
                setOpen(true)
            }
        }
    }, 1200);

    // Handle to Dialog.
    const handleClose = (value) => {
        setOpen(false);
        if (value === 'newMember') {
            showDialog({
                title: "Nuevo",
                content: <MemberForm onSave={handleContactAdd} authUser={authUser} />
            });
        }
        // setSelectedValue(value);
    };

    const handleContactAdd = useCallback(() => {
        hideDialog();
        // setContactsListRefresh(true);
    }, [hideDialog]); // Yo puedo agregar otra funcion ej: setContactsListRefresh

    return (
        <Search sx={sx}>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>

            <StyledInputBase
                placeholder="Buscar x dni"
                inputProps={{ 'aria-label': 'search' }}
                onChange={search}
            />
            <CustomDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                items={items}
            />
        </Search>
    );
};

export default SearchGlobal;
