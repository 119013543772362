export const API_URL = process.env.REACT_APP_API_URL
export const API_IMAGES_URL = "https://res.cloudinary.com/sanjua/image/upload/v1688669219/"
export const MONTHS = {
  '1': 'Enero',
  '2': 'Febrero',
  '3': 'Marzo',
  '4': 'Abril',
  '5': 'Mayo',
  '6': 'Junio',
  '7': 'Julio',
  '8': 'Agosto',
  '9': 'Septiembre',
  '10': 'Octubre',
  '11': 'Noviembre',
  '12': 'Diciembre',
};