import React from 'react';
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";

const JumboListNoDataPlaceholder = ({ children }) => {
    return (
        <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
            }}
        >
            {
                children ? children : (
                    <Div sx={{ textAlign: 'center', p: 3, m: 'auto' }}>
                        <Typography variant={"h3"} color={"text.secondary"}>No hay informacion disponible</Typography>
                    </Div>
                )
            }
        </Div>
    );
};

export default JumboListNoDataPlaceholder;