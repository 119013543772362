import React, { useState } from 'react';
import TabContext from "@mui/lab/TabContext";
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import styled from "@emotion/styled";

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));
const About = ({ profile }) => {
    const [value, setValue] = useState('1');
    const [open, setOpen] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePay = () => {
        setOpen(true);
    }

    return (
        <JumboCardQuick
            title={"Acerca de"}
            action={
                <TabContext value={value}>
                    <Div
                        sx={{
                            marginTop: -2.25,
                            marginBottom: -2.5,

                            '& .MuiTab-root': {
                                py: 2.5
                            }
                        }}
                    >
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Info" value="1" />
                            {/* <Tab label="Work" value="2" />
                            <Tab label="Education" value="3" /> */}
                        </TabList>
                    </Div>
                </TabContext>
            }
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            sx={{ mb: 3.75 }}

        >
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                }}
            >
                <ListItem
                    sx={{
                        width: { xs: '100%', sm: '50%', xl: '33.33%' }
                    }}
                >
                    <StyledListItemIcon>
                        <ApartmentIcon fontSize={"inherit"} />
                    </StyledListItemIcon>
                    {profile.lastPay ? (
                        <ListItemText
                            primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>
                                Ultimo mes pago</Typography>}
                            secondary={<Typography variant="body1" color="text.primary">
                                {profile.lastPay.slice(4)} / {profile.lastPay.slice(0, -2)}</Typography>}
                        />
                    ) : null}
                </ListItem>
                <ListItem
                    sx={{
                        width: { xs: '100%', sm: '50%', xl: '33.33%' }
                    }}
                >
                    <StyledListItemIcon>
                        <SchoolOutlinedIcon fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary"
                            mb={.5}>Categoria</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{profile.categoryPlayer}</Typography>}
                    />
                </ListItem>
                <ListItem
                    sx={{
                        width: { xs: '100%', sm: '50%', xl: '33.33%' }
                    }}
                >
                    <StyledListItemIcon>
                        <SchoolOutlinedIcon fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>Proximo Pago</Typography>}
                        secondary={<Button variant={"contained"} onClick={handlePay}>Pagar Abril</Button>}
                    />
                </ListItem>
                {/* <ListItem
                    sx={{
                        width: { xs: '100%', xl: '66.67%' }
                    }}
                >
                    <StyledListItemIcon>
                        <GroupsOutlinedIcon fontSize={"inherit"} />
                    </StyledListItemIcon>
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color="text.secondary" mb={.5}>4 Family
                            Members</Typography>}
                        secondary={
                            <Typography component={"div"} variant={'body1'}>
                                <Stack
                                    direction={"row"}
                                    flexWrap={"wrap"}
                                    sx={{}}
                                >
                                    <AvatarGroup
                                        max={5}
                                        sx={{
                                            '.MuiAvatar-root': {
                                                height: 32,
                                                width: 32,
                                                fontSize: 13,
                                                background: theme => theme.palette.grey[600]
                                            }
                                        }}
                                    >
                                        <Avatar alt="Remy Sharp" src={getAssetPath(`${ASSET_AVATARS}/avatar6.jpg`, "32x32")} />
                                        <Avatar alt="Remy Sharp" src={getAssetPath(`${ASSET_AVATARS}/avatar5.jpg`, "32x32")} />
                                        <Avatar alt="Remy Sharp" src={getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, "32x32")} />
                                        <Avatar alt="Remy Sharp" src={getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "32x32")} />
                                        <Avatar alt="Remy Sharp" src={getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "32x32")} />
                                        <Avatar alt="Remy Sharp" src={getAssetPath(`${ASSET_AVATARS}/avatar8.jpg`, "32x32")} />
                                    </AvatarGroup>
                                </Stack>
                            </Typography>
                        }
                    />
                </ListItem> */}
            </List>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Desea pagar el mes de Abril ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esta desición no podra ser cancelada luego de aceptarla.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>No</Button>
                    <Button onClick={() => setOpen(false)} autoFocus variant={"contained"}>
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
        </JumboCardQuick>
    );
};

export default About;
