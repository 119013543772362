import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemAvatar,
    Card,
    CardHeader,
    CardContent,
    Checkbox,
    Divider,
    Tooltip,
    Typography,
    Avatar,
    Stack,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import IconButton from "@mui/material/IconButton";
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import styled from "@emotion/styled";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Span from "@jumbo/shared/Span";
import Div from "@jumbo/shared/Div";
import { contactService } from "../../../../../services/contact-services";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import JumboChipsGroup from "@jumbo/components/JumboChipsGroup";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import MemberForm from "../MemberForm";
import useContactsApp from "../../hooks/useContactsApp";
import { useMutation } from "react-query";
import JumboGridItem from "@jumbo/components/JumboList/components/JumboGridItem";
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import MemberDetail from '../MemberDetail/MemberDetail';

const baseImgUrl = "https://res.cloudinary.com/sanjua/image/upload/v1688669219/";

const Item = styled(Span)(({ theme }) => ({
    minWidth: 0,
    flexGrow: 0,
    padding: theme.spacing(0, 1),
}));

const MemberItem = ({ member, view }) => {
    const { authUser } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();
    const { setContactsListRefresh } = useContactsApp();
    const [favorite, setFavorite] = React.useState(member?.starred);
    const deleteContactMutation = useMutation(contactService.delete, {
        onSuccess: () => {
            hideDialogAndRefreshContactsList();
        }
    });

    const hideDialogAndRefreshContactsList = React.useCallback(() => {
        hideDialog();
        setContactsListRefresh(true);
    }, [hideDialog, setContactsListRefresh]);


    const showContactDetail = React.useCallback(() => {
        showDialog({
            content: <MemberDetail member={member} onClose={hideDialogAndRefreshContactsList} authUser={authUser} />
        })
    }, [showDialog, member]);

    const getLastPay = (value) => {
        if (value) {
            const year = value.slice(0, 4);
            const month = value.slice(4);

            return `${month} / ${year}`;
        }
        return '';
    }


    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                showDialog({
                    title: `Actualizar datos de ${member.firstName} ${member.lastName}`,
                    content: <MemberForm member={member} onSave={hideDialogAndRefreshContactsList} authUser={authUser} edit={true} />
                });
                break;
            case 'delete':
                showDialog({
                    variant: 'confirm',
                    title: 'Está seguro de eliminarlo ?',
                    content: "No podrás recuperarlo luego",
                    onYes: () => deleteContactMutation.mutate(member),
                    onNo: hideDialog
                })
        }
    };

    if (view === "grid") {
        return (
            <JumboGridItem xs={12} lg={6}>
                <Card variant="outlined" elevation={0}>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ width: 48, height: 48 }} alt={member.firstName} src={`${baseImgUrl}${member?.image?.src}.${member?.image?.format}`} />
                        }
                        action={
                            <React.Fragment>
                                <StarIcon sx={{ color: 'warning.main', verticalAlign: 'middle', mr: 1 }} />
                                <IconButton>
                                    <MoreHorizIcon />
                                </IconButton>
                            </React.Fragment>
                        }
                        title={
                            <Typography
                                variant={"h6"}
                                color={"text.primary"}
                                mb={.25}
                            >
                                {member.firstName}
                            </Typography>}
                        subheader={
                            <Typography
                                variant={"body1"}
                                color={"text.secondary"}
                            >
                                {member.designation}
                            </Typography>}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <Divider sx={{ mb: 2 }} />
                        <List disablePadding>
                            <ListItem sx={{ p: theme => theme.spacing(.75, 1.5) }}>
                                <ListItemIcon sx={{ minWidth: 50 }}>
                                    <BusinessIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={member.dni}
                                />
                            </ListItem>
                            <ListItem sx={{ px: 1.5 }}>
                                <ListItemIcon sx={{ minWidth: 50 }}>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={getLastPay(member.lastPay)}
                                />
                            </ListItem>
                            <ListItem sx={{ px: 1.5 }}>
                                <ListItemIcon sx={{ minWidth: 50 }}>
                                    <AlternateEmailIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={member.email}
                                />
                            </ListItem>
                            <ListItem sx={{ px: 1.5 }}>
                                <ListItemIcon sx={{ minWidth: 50 }}>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={member.phone}
                                />
                            </ListItem>
                        </List>
                        <Divider sx={{ my: 2 }} />
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Checkbox sx={{ my: -.5 }} />
                            {
                                member.labels.length == 0 ? null : (
                                    <JumboChipsGroup
                                        chips={member.labels}
                                        mapKeys={{ label: "name" }}
                                        spacing={1}
                                        size={"small"}
                                        max={3}
                                    />
                                )
                            }
                        </Div>
                    </CardContent>
                </Card>
            </JumboGridItem>
        )
    }

    return (
        <React.Fragment>
            <JumboListItem
                componentElement={"div"}
                itemData={member}
                secondaryAction={
                    <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                            { icon: <EditIcon onClick={showContactDetail} />, title: "Editar", action: "edit" },
                            // { icon: <DeleteIcon />, title: "Delete", action: "delete" }
                        ]}
                        onClickCallback={handleItemAction}
                    />
                }
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderColor: 'divider',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    }
                }}
            >
                <ListItemIcon sx={{ minWidth: 40 }}>
                    {
                        favorite ?
                            (
                                <Tooltip title={"Starred"}>
                                    <StarIcon
                                        fontSize={"small"}
                                        sx={{ color: 'warning.main' }}
                                        onClick={() => setFavorite(!favorite)}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip title={"Not starred"}>
                                    <StarBorderIcon
                                        fontSize={"small"}
                                        sx={{ color: 'text.secondary' }}
                                        onClick={() => setFavorite(!favorite)}
                                    />
                                </Tooltip>
                            )
                    }
                </ListItemIcon>
                <ListItemAvatar onClick={showContactDetail}>
                    <Avatar alt={member.firstName} src={`${baseImgUrl}${member?.image?.src}.${member?.image?.format}`} />
                </ListItemAvatar>
                <ListItemText
                    onClick={showContactDetail}
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Stack direction={"row"} alignItems={"center"} sx={{ minWidth: 0 }}>
                                <Item
                                    sx={{
                                        flexBasis: { xs: '100%', sm: '50%', md: '25%' }
                                    }}
                                >
                                    <Typography variant={"h5"} fontSize={14} lineHeight={1.25} mb={0}
                                        noWrap>{member.firstName} {member.lastName}</Typography>
                                    <Typography
                                        variant={"body1"}
                                        noWrap
                                        color={'text.secondary'}
                                        sx={{
                                            display: { sm: 'none' }
                                        }}
                                    >
                                        {member.dni}
                                    </Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '28%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{getLastPay(member.lastPay)}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { sm: '50%', md: '28%' },
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{member.dni}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '25%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <Typography variant={"body1"} noWrap>{member.phone}</Typography>
                                </Item>
                                <Item
                                    sx={{
                                        flexBasis: { md: '22%' },
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                >
                                    <JumboChipsGroup
                                        chips={member.labels}
                                        mapKeys={{ label: "name" }}
                                        spacing={1}
                                        size={"small"}
                                        max={1}
                                    />
                                </Item>
                            </Stack>
                        </Typography>
                    }
                />
            </JumboListItem>
        </React.Fragment>
    );
};
/* Todo contact, view prop define */
export default MemberItem;
