import { API_URL } from "app/utils/constants/config";
import jwtAuthAxios from "./auth/jwtAuth";
import axios from "./config";

const apiUrl = API_URL;
const resource = 'members';

const memberService = {};

memberService.getMembers = async ({ queryKey }) => {
    const { page, limit, queryParams } = queryKey[queryKey.length - 1];
    const { data } = await jwtAuthAxios.get(`${apiUrl}/${resource}`, {
        params: {
            page: page,
            limit: limit,
            ...queryParams,
        }
    });
    return { members: data };
};

memberService.getYearsByMember = async (memberId) => {
    const { data } = await jwtAuthAxios.get(`${apiUrl}/memberPayments/${memberId}`);
    return data;
};

memberService.addMemberPayments = async (payments) => {
    const { data } = await jwtAuthAxios.post(`${apiUrl}/memberPayments`, payments);
    return data;
};

memberService.getRecentConversations = async () => {
    const { data } = await axios.get("/chats/recent-conversations");
    return data;
};

memberService.getMember = async ({ queryKey }) => {
    const { id } = queryKey[queryKey.length - 1];
    const { data } = await axios.get("/chats/conversation", {
        conversationID: id
    });
    return data;
};

memberService.addMember = async (memberData) => {
    memberData.keywords = getKeywords(memberData);
    const { data } = await jwtAuthAxios.post(`${apiUrl}/members`, memberData);
    return data;
};

memberService.updateMember = async (memberData) => {
    delete memberData.__v;
    delete memberData.lastPay;
    memberData.keywords = getKeywords(memberData);
    const { data } = await jwtAuthAxios.put(`${apiUrl}/members`, memberData);
    return data;
};

memberService.getConversationByContactID = async ({ queryKey }) => {
    const { id } = queryKey[queryKey.length - 1];
    const { data } = await axios.get("/chats/contact/conversation", {
        contactID: id
    });
    return data;
};

export { memberService };


function getKeywords(data) {
    const names = data.firstName.toLowerCase().split(' ');
    const lastName = data.lastName.toLowerCase().split(' ');
    const category = data.category.toLowerCase().split(' ');
    const dni = data.dni.toString().toLowerCase().split(' ');
    return [...names, ...lastName, ...category, ...dni];
}