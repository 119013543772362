import React from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {
    Avatar,
    Button,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import { blue } from "@mui/material/colors";
import code from "../Dialogs/demo-code/simple-dialog.txt";
import { ASSET_AVATARS } from "../../../../utils/constants/paths";
import Div from "@jumbo/shared/Div";
import { getAssetPath } from "../../../../utils/appHelpers";

const CustomDialog = ({ selectedValue, open, onClose, items = [] }) => {
    const handleListItemClick = (value) => {
        onClose(value);
    };
    return (
        <Dialog onClose={() => onClose(selectedValue)} open={open}>
            {/* <DialogTitle>Seleccione uno</DialogTitle> */}
            <List sx={{ pt: 0 }}>
                {items?.map((item, k) => (
                    <ListItem button onClick={() => handleListItemClick(item)} key={k}>
                        <ListItemAvatar>
                            <Avatar
                                sx={{ bgcolor: blue[100], color: blue[600] }}
                                alt={"john"}
                                src={getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40")}
                            />
                        </ListItemAvatar>
                        <ListItemText primary={`${item.lastName} ${item.firstName}`} />
                    </ListItem>
                ))}

                {items.length === 0 && (
                    <ListItem autoFocus button onClick={() => handleListItemClick('newMember')}>
                        <ListItemAvatar>
                            <Avatar alt={"avatar1"} src={getAssetPath(`${ASSET_AVATARS}/avatar13.jpg`, "40x40")} />
                        </ListItemAvatar>
                        <ListItemText primary="Agregar nuevo miembro" />
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
}

export default CustomDialog;
